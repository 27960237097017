import { DatePipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldWrapper } from '@ngx-formly/core';
import { DEFAULT_POLLING_INTERVAL } from '@shared/constants';
import { NewServiceRequest } from 'app/modules/task-feed/models/new-service-request.model';
import { interval, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'hmt-long-term-contract-summary',
  templateUrl: './long-term-contract-summary.component.html',
  styleUrl: './long-term-contract-summary.component.scss',
  providers: [DatePipe],
})
export class LongTermContractSummaryComponent extends FieldWrapper implements OnInit, OnDestroy {
  dialogRef = inject(MatDialog);
  datePipe = inject(DatePipe);
  unsubscribe$ = new Subject<void>();

  serviceType = signal<string>('');
  jobFileName = signal<{ key: string; value: string }>({ key: 'Job Title', value: '' });
  countryOfOrigin = signal<{ key: string; value: string }>({ key: 'Country of Origin', value: '' });
  originLocation = signal<{ key: string; value: string }>({ key: '', value: '' });
  destinationLocation = signal<{ key: string; value: string }>({ key: '', value: '' });
  countryOfDestination = signal<{ key: string; value: string }>({ key: 'Country of Destination', value: '' });
  incoterm = signal<{ key: string; value: string }>({ key: 'Incoterm', value: '' });
  shipmentMode = signal<{ key: string; value: string }>({ key: 'Shipment Mode', value: '' });
  carrier = signal<{ key: string; value: string }>({ key: 'Carrier', value: '' });
  vessel = signal<{ key: string; value: string }>({ key: 'Vessel', value: '' });
  estimation = signal<{ key: string; value: string }>({ key: 'Estimation', value: '' });
  cargoReadyDate = signal<{ key: string; value: string }>({ key: 'Cargo Ready Date', value: '' });
  inHouseReadyDate = signal<{ key: string; value: string }>({ key: 'Required in House Date', value: '' });
  flight = signal<{ key: string; value: string }>({ key: 'Flight', value: '' });
  contractId = signal<string>('');

  isTenderClosed = signal<boolean>(false);
  tenderClosingInTimeCounterValue = signal<string>('');

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.populateData();

    interval(DEFAULT_POLLING_INTERVAL)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.calculateTenderClosingInTime();
      });
  }

  private formatDate(date: string | Date | null | undefined): string {
    if (!date) return '';

    try {
      const dateValue = typeof date === 'string' ? new Date(date) : date;
      return this.datePipe.transform(dateValue, 'dd/MM/yy HH:mm') || '';
    } catch (error) {
      console.warn('Invalid date format:', date);
      return '';
    }
  }

  private populateData(): void {
    const serviceRequest: NewServiceRequest = this.formState?.serviceRequest;
    if (serviceRequest) {
      this.contractId.set(serviceRequest.metadata['longTermContractId']);
      this.serviceType.set(serviceRequest?.metadata['serviceType']);
      this.jobFileName.set({ key: 'Job Title', value: serviceRequest.sourceJobFile?.title });
      this.countryOfOrigin.set({
        key: 'Country of Origin',
        value: serviceRequest.metadata['originCountry'] ?? '',
      });
      this.countryOfDestination.set({
        key: 'Country of Destination',
        value: serviceRequest.metadata['destinationCountry'] ?? '',
      });
      this.incoterm.set({ key: 'Incoterm', value: serviceRequest.metadata['incoterm'] ?? '' });
      this.shipmentMode.set({ key: 'Shipment Mode', value: serviceRequest.metadata['shipmentMode'] ?? '' });
      this.carrier.set({ key: 'Carrier', value: serviceRequest.metadata['carrier'] ?? '' });
      this.estimation.set({
        key: 'Estimation',
        value: `${serviceRequest.metadata['currency'] ?? ''} ${(serviceRequest.metadata['estimatedValue'] ?? 0).toFixed(2)}`,
      });
      this.cargoReadyDate.set({
        key: 'Cargo Ready Date',
        value: this.formatDate(serviceRequest.metadata['cargoReadyDate']),
      });
      this.inHouseReadyDate.set({
        key: 'Required in House Date',
        value: this.formatDate(serviceRequest.metadata['inHouseReadyDate']),
      });

      if (this.serviceType() === 'OCEAN-FREIGHT') {
        this.vessel.set({ key: 'Vessel', value: serviceRequest.metadata['vessel'] ?? '' });
        this.destinationLocation.set({
          key: 'Port Of Destination',
          value: serviceRequest.metadata['portOfDestination'] || '',
        });
        this.originLocation.set({
          key: 'Port Of Origin',
          value: serviceRequest.metadata['portOfOrigin'] || '',
        });
      } else {
        this.flight.set({ key: 'Flight', value: serviceRequest.metadata['vessel'] || '' });
        this.destinationLocation.set({
          key: 'Airport Of Destination',
          value: serviceRequest.metadata['destinationLocation'] || '',
        });
        this.originLocation.set({
          key: 'Airport Of Origin',
          value: serviceRequest.metadata['originLocation'] || '',
        });
      }
    }
  }

  calculateTenderClosingInTime() {
    const serviceRequest: NewServiceRequest = this.formState?.serviceRequest;
    const to = serviceRequest?.expiredAt;
    const now = new Date();
    const diff = to ? new Date(to).getTime() - now.getTime() : 0;
    if (diff < 0) {
      this.isTenderClosed.set(true);
      this.formState.expired = true;
      this.tenderClosingInTimeCounterValue.set(
        this.datePipe.transform(to ? new Date(to) : now, 'yyyy/MM/dd | HH:mm') ?? ''
      );
    } else {
      this.tenderClosingInTimeCounterValue.set(this.formatTime(diff));
    }
  }

  formatTime(diff: number) {
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    return `${days}D:${hours}H:${minutes}M:${seconds}S`;
  }

  viewLongTermContract(contractId: string): void {
    this.field.props['onClick'](this.contractId());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
